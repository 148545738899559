(function ($) {

  function titleAnimation() {
    $(".girlsinc-title-ui").each(function(index) {
      var thisEl = $(this);
      if(verge.inViewport(thisEl)) {
        thisEl.addClass('active-title');
      }
    });
  }

  function heroAnimation(scroll) {
    var bgSizeInitial = scroll * 0.2;
    var bgSizeFinal = 100 + bgSizeInitial;

    $('.page-header').each(function() {

      $(this).css({'background-size': bgSizeFinal + '%'});

      $(this).children('.header-content').css({
        'top': '-' + scroll / 2 + 'px'
      });

      var blurAmount = scroll / 50;
      $(this).css({
        'filter': 'blur(' + blurAmount + 'px)',
        '-webkit-filter': 'blur(' + blurAmount + 'px)',
        '-ms-filter:': 'blur(' + blurAmount + 'px)'
      });
    });
  }

  $(function(){
    var scrollLoc = $("body, html").scrollTop();

    $(window).load(function() {
      titleAnimation();
      heroAnimation(scrollLoc);
    });

    $(window).scroll(function() {
      scrollLoc = $("body, html").scrollTop();

      titleAnimation();
      heroAnimation(scrollLoc);
    });
  });

})(jQuery);
